import { useQuery } from '@apollo/client';
import LocationAgreements from 'components/agreement/LocationAgreements';
import PageTitle from 'components/common/PageTitle';
import { StyledRow } from 'components/common/styled';
import Equipment from 'components/equipment/Equipment';
import { Address, GetLocationDetailsQuery } from 'generated/graphql';
import { DbConstants } from 'generated/smt-constants';
import { GET_LOCATION_DETAILS } from 'graphql/query/location';
import { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { formatAddress } from 'services/formattingService';
import { hasPermission } from 'services/userService';
import LocationAgreementDetails from './LocationAgreementDetails';
import LocationDetailView, { LocationViewPerspective } from './LocationDetailView';

function LocationDetails() {
  const { locationId } = useParams<{ locationId: string }>();
  const [locationState, setLocationState] = useState<{
    location: any;
    dumpsters: any[];
    broker: any;
    assets: any[];
    agreements: any[] | undefined;
  }>();

  // set up react hook forms
  const formHandler = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    defaultValues: {
      name: '',
      serviceType: 'none',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zipCode: '',
      inactive: null,
    },
  });

  const { loading, data } = useQuery<GetLocationDetailsQuery>(GET_LOCATION_DETAILS, {
    variables: { id: locationId },
    onCompleted: response => {
      setLocationState({
        location: response.location,
        agreements: response.location?.agreements,
        dumpsters: response.location?.dumpsters || [],
        broker:
          (response.location?.locationBrokers?.length || 0) > 0 ? response.location?.locationBrokers[0] : undefined,
        assets: response.location?.locationAssets || [],
      });
      formHandler.reset(response?.location as any);
    },
  });

  function LocationDetailHeader() {
    return (
      <StyledRow>
        <PageTitle>{formatAddress(locationState?.location?.address as Address | undefined)}</PageTitle>
      </StyledRow>
    );
  }

  const updateContextWithLocation: (updatedLocation: any) => void = (updatedLocation: any) => {
    if (locationState && updatedLocation)
      setLocationState({
        location: { ...locationState.location, inactive: updatedLocation?.inactive || null },
        agreements: locationState.agreements,
        dumpsters: locationState.dumpsters,
        broker: locationState.broker,
        assets: locationState.assets,
      });
  };
  const updateContextWithDumpsters: (dumpsters: any[]) => void = (dumpsters: any[]) => {
    if (dumpsters && locationState) setLocationState({ ...locationState, dumpsters: [...dumpsters] });
  };
  const updateContextWithBroker: (broker: any) => void = (broker: any) => {
    if (broker && locationState) {
      setLocationState({ ...locationState, broker });
    }
  };
  const updateContextWithAssets: (assets: any[]) => void = (assets: any[]) => {
    if (assets && locationState) setLocationState({ ...locationState, assets: [...assets] });
  };

  const updateContextWithAgreement: (updatedAgreement: any) => void = (updatedAgreement: any) => {
    if (locationState && updatedAgreement) {
      const newAgreementsArray = locationState.agreements?.slice(0) || [];
      const match = newAgreementsArray.find(x => x.id === updatedAgreement.id);
      if (match) {
        newAgreementsArray[newAgreementsArray?.indexOf(match)] = updatedAgreement;
      } else {
        newAgreementsArray.push(updatedAgreement);
      }
      setLocationState({
        location: locationState.location,
        agreements: newAgreementsArray,
        dumpsters: locationState.dumpsters,
        broker: locationState.broker,
        assets: locationState.assets,
      });
    }
  };

  if (!loading && (locationState || data)) {
    const o = locationState || data;
    const locationClone = o ? { ...o } : undefined;
    const agreementCount = o?.location?.agreements?.length || 0;
    const primaryAgreement = agreementCount > 0 ? o?.location?.agreements[0] : {};
    if (agreementCount > 1) {
      const inactiveAgreements = o?.location?.agreements?.filter(
        a => a.agreementStatus === DbConstants.AgreementStatus.Inactive
      );
      const pendingAgreements = o?.location?.agreements?.filter(
        a =>
          a.agreementStatus === DbConstants.AgreementStatus.AwaitingApproval ||
          a.agreementStatus === DbConstants.AgreementStatus.Approved ||
          a.agreementStatus === DbConstants.AgreementStatus.Rejected
      );
      const activeAgreements = o?.location?.agreements?.filter(
        a => a.agreementStatus === DbConstants.AgreementStatus.Active
      );
      // TODO: come up with a accurate way to determine the active agreement.
    }

    return (
      <>
        <LocationDetailHeader />
        <FormProvider {...formHandler}>
          <LocationDetailView
            perspective={LocationViewPerspective.Customer}
            location={locationClone}
            editPermission={DbConstants.Permission.CustomerLocationCrud}
          />
        </FormProvider>
        <LocationAgreements
          agreementUpdated={updateContextWithAgreement}
          locationId={locationId}
          dumpsterCount={locationState?.dumpsters?.filter(d => !d?.deleted)?.length || 2}
          currentAgreement={primaryAgreement}
        />
        <Equipment
          location={locationState?.location}
          broker={locationState?.broker}
          dumpsters={locationState?.dumpsters}
          assets={locationState?.assets}
          brokerUpdated={updateContextWithBroker}
          dumpstersUpdated={updateContextWithDumpsters}
          assetsUpdated={updateContextWithAssets}
          readOnly={!hasPermission(DbConstants.Permission.CustomerLocationCrud)}
        />
      </>
    );
  }

  return <div>...loading</div>;
}

export default LocationDetails;
