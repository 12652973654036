import { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { Spin } from 'antd';
import styled from 'styled-components';
import { useQuery, useReactiveVar } from '@apollo/client';
import { cognitoUserVar } from 'apollo/context';

import { GetUserQuery } from 'generated/graphql';
import { GET_USER } from './graphql/query/user';

import './App.less';
import amplifyConfig from './amplifyConfig';
import CustomerManagement from './components/customer/CustomerManagement';
import Dashboard from './components/dashboard/Dashboard';
import FranchiseManagement from './components/franchise/FranchiseManagement';
import SignIn from './components/SignIn';
import SystemLayout from './components/layout/SystemLayout';
import LoadingIndicator from './components/common/LoadingIndicator';
import Schedule from 'components/schedule/Schedule';
import Financials from 'components/stubbedPages/Financials';
import Jobs from 'components/stubbedPages/Jobs';
import Reports from 'components/stubbedPages/Reports';
import Directory from 'components/stubbedPages/Directory';
import Settings from 'components/settings/Settings';
import Corporate from 'components/stubbedPages/Coporate';
import Profile from 'components/stubbedPages/Profile';
import ExampleManagement from 'components/styleGuide/listAndEditExample/ExampleManagement';
import LeadManagement from 'components/leads/LeadManagement';
import StyleGuideHome from 'components/styleGuide/StyleGuideHome';
import {
  convertToSignedInUser,
  getSelectedFranchise,
  hasAccessToAllFranchises,
  setSelectedFranchise,
  setSignedInUser,
} from 'services/userService';
import { FranchiseIdentifier } from 'models/franchiseIdentifier';
import InvoiceTableView from 'components/invoice/InvoiceTableView';
import Routes from 'components/routes/Routes';
import UtilitiesHome from 'components/teamTools/UtilitiesHome';
import { ScheduleManagement } from 'components/schedule/ScheduleManagement';
import TruckManagement from 'components/settings/trucks/TruckManagement';
import EmployeeManagement from 'components/settings/employees/EmployeeManagement';
import Agreement from 'components/agreement/Agreement';

Amplify.configure({
  ...amplifyConfig,
});

const LoadingContainer = styled<typeof Spin>(Spin)`
  max-height: 100% !important;
  position: fixed !important;
`;

Spin.setDefaultIndicator(<LoadingIndicator />);
function App() {
  // init state to store user and show loader
  const loadingStatus = useRef<boolean>(false);
  const cognitoUser = useReactiveVar(cognitoUserVar);

  // listen for sign in + out events, if neither are happening check if user exists
  useEffect(() => {
    Hub.listen('auth', ({ payload }) => {
      if (payload.event === 'signIn') {
        return getAuthenticatedUser();
      }
      if (payload.event === 'signOut') {
        cognitoUserVar('');
        loadingStatus.current = false;
        return false;
      }
    });
    getAuthenticatedUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get user
  const getAuthenticatedUser = async () => {
    // if we are already loading, return;
    if (loadingStatus.current) return;

    loadingStatus.current = true;
    try {
      const cognitoUserObj = await Auth.currentAuthenticatedUser();
      cognitoUserVar(cognitoUserObj.attributes);

      const jwtToken = cognitoUserObj?.signInUserSession?.accessToken?.jwtToken;
      if (jwtToken) localStorage.setItem('smt-token', jwtToken);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      loadingStatus.current = false;
    }
  };
  return (
    <LoadingContainer spinning={loadingStatus.current} delay={200}>
      {!cognitoUser ? <SignIn /> : <AuthenticatedApp cognitoUser={cognitoUser} />}
    </LoadingContainer>
  );
}

function AuthenticatedApp({ cognitoUser }) {
  // get the search state, which includes everything
  const [propagatedFranchise, setUpdatedFranchise] = useState<FranchiseIdentifier>();

  const { loading, error, data } = useQuery<GetUserQuery>(GET_USER, {
    variables: { id: cognitoUser.preferred_username },
  });
  if (loading) return <LoadingContainer spinning>Authorizing...</LoadingContainer>;
  if (error)
    // TODO: Add proper error handling to this application.
    return (
      <div>
        ERROR: <pre>{JSON.stringify(error, null, 4)}</pre>
      </div>
    );

  const signedInUser = convertToSignedInUser(data);
  setSignedInUser(signedInUser);
  let currentFranchise = getSelectedFranchise();
  if (!currentFranchise && signedInUser) {
    const defaultFranchiseLoaded = data?.franchises && (data?.franchises?.length || 0) > 0;
    const canAccessAll = hasAccessToAllFranchises();
    if (signedInUser.franchises.length > 0) {
      setSelectedFranchise(signedInUser.franchises[0]);
    } else if (canAccessAll && defaultFranchiseLoaded) {
      const firstFranchise = data.franchises[0];
      setSelectedFranchise({ id: firstFranchise.id, name: firstFranchise.name } as FranchiseIdentifier);
    }
    currentFranchise = getSelectedFranchise();

    if (!currentFranchise) {
      return (
        <div>You do not currently have a franchise assigned to your user account. Please contact your system admin</div>
      );
    }
  }

  if (currentFranchise && propagatedFranchise?.id !== currentFranchise?.id) {
    setUpdatedFranchise(currentFranchise);
  }

  return (
    <Router>
      <SystemLayout franchise={propagatedFranchise}>
        <Switch>
          <Route path='/login'>
            <div>
              <SignIn />
            </div>
          </Route>
          <Route path='/schedule'>
            <Schedule />
          </Route>
          <Route path='/financials'>
            <Financials />
          </Route>
          <Route path='/invoices'>
            <InvoiceTableView />
          </Route>
          <Route path='/leads'>
            <LeadManagement />
          </Route>
          <Route path='/customers'>
            <CustomerManagement />
          </Route>
          <Route path='/agreement'>
            <Agreement />
          </Route>
          <Route path='/route'>
            <Routes />
          </Route>
          <Route path='/jobs'>
            <Jobs />
          </Route>
          <Route path='/reports'>
            <Reports />
          </Route>
          <Route path='/directory'>
            <Directory />
          </Route>
          <Route path='/franchise'>
            <FranchiseManagement setUpdatedFranchise={(x: FranchiseIdentifier) => setUpdatedFranchise(x)} />
          </Route>
          <Route path='/settings'>
            <Settings />
          </Route>
          <Route path='/corporate'>
            <Corporate />
          </Route>
          <Route path='/profile'>
            <Profile />
          </Route>
          <Route path='/poc'>
            <ExampleManagement />
          </Route>
          <Route path='/style-guide'>
            <StyleGuideHome />
          </Route>
          <Route path='/create-smash-order'>
            <ScheduleManagement />
          </Route>
          <Route path='/utilities'>
            <UtilitiesHome />
          </Route>
          <Route path='/trucks'>
            <TruckManagement />
          </Route>
          <Route path='/employees'>
            <EmployeeManagement />
          </Route>
          <Route path='/'>
            <Dashboard />
          </Route>
        </Switch>
      </SystemLayout>
    </Router>
  );
}

export default App;
